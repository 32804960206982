<template>
  <div>
    <div class="section mb10">
      <div class="section-row">
        <div class="section-col">
          <span class="fz12">开始时间：</span>
          <div class="section-date-start" @click="onDatePickerOpen('startDate')">
            {{ startDate }}
          </div>
        </div>
        <div class="section-col">
          <span class="fz12">结束时间：</span>
          <div class="section-date-end" @click="onDatePickerOpen('endDate')">
            {{ endDate }}
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="dateShowPicker" position="bottom">
      <van-datetime-picker
        v-model="currentTime"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onDatePickerConfirm"
        @cancel="onDatePickerClose"
      />
    </van-popup>

    <div class="chart-container mb10">
      <!-- <ve-line :data="chartData" /> -->
      <EasyLine
        class="chart-img"
        :easyData="chartData"
        :customConfigs="currentCustomConfigs"
      />
    </div>

    <div class="records" v-if="0">
      <van-cell title="开课记录" />
      <!-- <div class="list">
        <div v-for="(item,index) in list" :key="index">
          <div class="list-item van-hairline--bottom">
            <div class="list-item-hd">
              <div class="list-item-title">{{ item.book_weeks }}</div>
              <div class="list-item-txt">{{ item.class_date | formatEnd }}</div>
              <div class="list-item-txt">{{ item.class_start }}</div>
            </div>
            <div class="list-item-bd">
              <div class="list-item-title">{{ item.course_name }}</div>
              <div class="list-item-txt">{{ item.course_studio.studio_name }}</div>
              <div class="list-item-txt">上课教室：{{ item.room_name }} 上课老师：{{ item.course_teacher.teacher_name }}</div>
            </div>
            <div class="list-item-ft">
              <div class="list-item-status">{{ item.book_status }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <van-col v-for="(item,index) in list" :key="index" span="24">
          <div class="list-item van-hairline--bottom">
            <div class="list-item-hd">
              <div class="list-item-title">{{ item.course_weeks }}</div>
              <div class="list-item-txt">{{ item.class_date | formatEnd }}</div>
              <div class="list-item-txt">{{ item.class_time.substr(0,5) }}</div>
            </div>
            <div class="list-item-bd">
              <div class="list-item-title">{{ item.course_name }}</div>
              <div class="list-item-txt">{{ item.course_studio.studio_name }}</div>
              <div class="list-item-txt">
                <van-icon style="margin-right:5px" class-prefix="icon" size="12" name="PersonalOutline" />
                <span class="list-item-txt-teacher van-ellipsis">{{ item.course_teacher.teacher_name }}</span>
                <van-icon style="margin:0 5px" class-prefix="icon" size="12" name="HomeOutline" />
                <span class="list-item-txt-room van-ellipsis">{{ item.room_name }} </span>
                <!-- 上课教室：{{ item.room_name }} 上课老师：{{ item.course_teacher.teacher_name }} -->
              </div>
            </div>
            <div class="list-item-ft">
              <div class="list-item-status">{{ item.signin_count }} / {{ item.reserve_count }}</div>
            <!-- <van-button type="primary" size="small" :to="'/student/ask-leave?book_id=' + item.book_id">去请假</van-button> -->
            </div>
          </div>
        </van-col>
      </van-list>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Tab, Tabs, DatetimePicker, Field, Picker, Tag } from 'vant'
import VeLine from 'v-charts/lib/line.common'
import EasyLine from '@/components/EasyLine/line'

import dayjs from 'dayjs'

export default {
  name: 'TacherAnalysis',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [EasyLine.name]: EasyLine,
    [VeLine.name]: VeLine,
    [Tag.name]: Tag
  },
  filters: {
    formatEnd(value) {
      return value && value.slice(-5)
    }
  },
  mixins: [PageMixin],
  data() {
    const nowDate = dayjs(nowDate).format('YYYY-MM-DD')
    // const startYear = dayjs().startOf('year').format('YYYY-MM-DD')
    const startMonth = dayjs().startOf('month').format('YYYY-MM-DD')
    // const startWeek = dayjs().startOf('week').format('YYYY-MM-DD')
    return {
      //
      dateType: 'startDate', // startDate,endDate
      startDate: startMonth,
      endDate: nowDate,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      currentTime:'',
      currentStart: dayjs().startOf('month').$d,
      currentEnd: new Date(),
      dateShowPicker: false,
      // 图表
      chartData: { },
      // 上课记录
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      currentCustomConfigs:['rate'],
    }
  },
  created() {
    this.getData()
    // this.getList()
  },
  methods: {
    updateChart() {
      this.getData()
      // this.getList()
    },
    getData() {
      this.$toast.loading({
        message: '统计加载中...',
        forbidClick: true
      })
      const params = {}
      params.start_date = this.startDate
      params.end_date = this.endDate
      this.$api.employee_scheduleanalysis(params)
        .then(res => {
          this.$toast.clear()
          this.chartData = res.data
        })
    },
    onListLoad() {
      this.getList()
    },
    getList() {
      this.$api.employee_schedulelist({ 'per-page': this.pageSize, page: this.pageCurrent,search:{start_date:this.startDate,end_date:this.endDate}}).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    // getList() {
    //   this.$api.employee_schedulelist()
    //     .then(res => {
    //       this.list = res.data
    //     })
    // },
    // 开始结束时间
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else {
        return `${val}日`
      }
    },
    onDatePickerOpen(type) {
      if(type == 'startDate'){
        this.currentTime = this.currentStart
      } else{
        this.currentTime = this.currentEnd
      }
      this.dateType = type
      this.dateShowPicker = true
    },
    onDatePickerClose() {
      this.dateShowPicker = false
    },
    onDatePickerConfirm(time) {
      if (this.dateType === 'endDate') {
        this.currentEnd = time;
        const timestampEnd = dayjs(time).valueOf()
        const timestampStart = dayjs(this.startDate).valueOf()
        if (timestampEnd < timestampStart) {
          this.$toast('结束时间不能小于开始时间')
          return
        }
      }else{
        this.currentStart = time;
      }
      this[this.dateType] = dayjs(time).format('YYYY-MM-DD')
      this.onDatePickerClose()
      this.updateChart()
    }
  }
}
</script>

<style lang="less" scoped>

  .container{
    padding: 10px 0;
  }

  .grid-title{
    font-size: 16px;
    color: #333;
  }

  .tab-container{

  }

  .section{
    background: #fff;
    &-row{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &-col{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-date{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-start{
        box-sizing: border-box;
        width: 100px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 12px;
        border: 1px solid #eee;
      }
      &-end{
        box-sizing: border-box;
        width: 100px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 12px;
        border: 1px solid #eee;
      }
    }
  }

  .chart-container{
    box-sizing: border-box;
    padding: 10px 15px;
    background: #fff;
  }

  .list{
    &-item{
      background: #fff;
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;
      &-hd{
        width: 50px;
      }
      &-bd{
        flex: 1;
      }
      &-title{
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
      }
      &-txt{
        line-height: 1.8;
        font-size: 12px;
        color: #666;
      }
      &-ft{
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
</style>

